html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fdfdfd;
  font-family: 'Nunito Sans Variable';
}

h1 {
  font-weight: 900;
}

p, li {
  font-size: 20px;
}
